<template>
  <iframe
    frameborder="1"
    :height="height < 400 ? '500px' : `${height}px`"
    width="100%"
    scrolling="no"
    :src="`${VUE_APP_URL_FRONTEND_NEW_MITRA}/overview/missing-trx/${cardId}?iframe=true`"
    allow="clipboard-read; clipboard-write"
  />
</template>

<script>
export default {
  data() {
    return {
      VUE_APP_URL_FRONTEND_NEW_MITRA: process.env.VUE_APP_URL_FRONTEND_NEW_MITRA,
      cardId: this.$route.params.id,
      height: 0,
    }
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.origin === this.VUE_APP_URL_FRONTEND_NEW_MITRA) {
        const {
          type, data,
        } = event.data

        if (type === 'SET_DIMENSION') {
          this.height = Math.floor(data.height)
        }
      }
    })
  },
}
</script>
